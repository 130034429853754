import React, { ReactNode } from "react";
import Head from "next/head";
import Footer from "./Footer";
import Navbar from "../Navbar";
import { useRouter } from "next/router";
import CommonUtils from "@/utils/CommonUtils";

type Props = {
  children?: ReactNode;
  title: string;
  date?: string;
  description: string;
  imageUrl?: string;
  schema?: any;
};

const SiteLayout = ({
  children,
  title,
  description,
  date,
  imageUrl,
  schema,
}: Props) => {
  const router = useRouter();
  const { locale } = router;

  let canonical = router.asPath;

  canonical = canonical.replace(/\/$/, "");

  const page = router.query.page;
  const pageNumber = page ? parseInt(page as string) : 1;

  const doNotIndex = pageNumber > 5;

  const ls = locale === "sw" ? "" : `/${locale}`;

  let pathName = router.pathname.replace("/", "");

  let isHomePage = pathName === "" || pathName === "en" ? true : false;

  let baseURL = CommonUtils.baseURL;

  let baseURLWithLocale = locale !== "sw" ? `${baseURL}/en` : baseURL;

  let appName = CommonUtils.appName;

  let fallbackImageUrl = CommonUtils.fallbackImageUrl;
  imageUrl ? imageUrl : fallbackImageUrl;
  //get full url
  let currentUrl = `${baseURLWithLocale}${router.asPath}`;

  let _imageURL = imageUrl || fallbackImageUrl;

  let homeJsonLDSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: appName,
    description: description,
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+255 621 394 783",
      contactType: "customer service",
    },
    logo: `${baseURL}/logo.png`,
    image: `${baseURL}/makazi-mapya-image.png`,
    brand: {
      "@type": "Brand",
      name: appName,
      description: description,
      image: fallbackImageUrl,
      url: currentUrl,
    },
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": currentUrl,
    },
    slogan: "Find your dream property in Tanzania",
  };

  return (
    <div className={`relative font-rubik font-light`}>
      <Head>
        {process.env.NODE_ENV === "production" && (
          <>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-YCX419TQX9"
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'G-YCX419TQX9');
                  `,
              }}
            />
          </>
        )}
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:site_name" content={appName} />
        {ls === "sw" && <meta property="og:locale" content="sw_TZ" />}
        {ls === "en" && <meta property="og:locale" content="en_US" />}
        {doNotIndex && <meta name="robots" content="noindex, follow" />}
        <link rel="canonical" href={`https://makazimapya.com${canonical}`} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={_imageURL} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={currentUrl} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <meta property="twitter:image" content={_imageURL} />
        <meta name="theme-color" content="#1ED760" />
        <meta name="msapplication-TileColor" content="#1ED760" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="application-name" content={appName} />
        <meta name="apple-mobile-web-app-title" content={appName} />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="#282F39" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        ></link>
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        ></link>
        <link rel="manifest" href="/site.webmanifest"></link>
        {isHomePage && (
          <script type="application/ld+json">
            {`
              ${JSON.stringify(homeJsonLDSchema)}
            `}
          </script>
        )}
        {schema && (
          <script type="application/ld+json">
            {`
              ${JSON.stringify(schema)}
            `}
          </script>
        )}
      </Head>
      <Navbar navClass={undefined} topnavClass={undefined} />
      <section className="relative mt-[130px]">{children}</section>
      <Footer />
    </div>
  );
};

export default SiteLayout;
